"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toAbsoluteUrl = void 0;

var toAbsoluteUrl = function toAbsoluteUrl(pathname) {
  return process.env.PUBLIC_URL + pathname;
};

exports.toAbsoluteUrl = toAbsoluteUrl;