"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAllBots = fetchAllBots;
exports.fetchBotsbyTags = fetchBotsbyTags;
exports.fetchAllTags = fetchAllTags;
exports.fetchBotById = fetchBotById;
var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error("ServerError");
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error("ApplicationError");

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchAllBots(authToken, orgId) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer?length=0&start=0&org_id=").concat(orgId), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchBotsbyTags(authToken, tags, orgId) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer?length=0&start=0&tags=").concat(tags, "&org_id=").concat(orgId), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchAllTags(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/tag?length=0&start=0"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchBotById(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer/").concat(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}