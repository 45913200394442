"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _uuid = require("uuid");

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var UUIDUtil = function UUIDUtil() {
  _classCallCheck(this, UUIDUtil);
};

exports["default"] = UUIDUtil;

_defineProperty(UUIDUtil, "resetUniqueId", function () {
  if (localStorage.getItem("dhan_bot_userID")) {
    localStorage.removeItem("dhan_bot_userID");
  }
});

_defineProperty(UUIDUtil, "getUniqueId", function () {
  var uuid = (0, _uuid.v4)();
  return uuid;
});

_defineProperty(UUIDUtil, "getUniqueIdWithState", function () {
  var uuid = (0, _uuid.v4)();
  var parsedState = UUIDUtil.getSetLocalStorage(uuid);
  var dhan_bot_userID = parsedState.dhan_bot_userID;
  return dhan_bot_userID;
});

_defineProperty(UUIDUtil, "getSetLocalStorage", function (id) {
  try {
    if (!localStorage.getItem("dhan_bot_userID")) {
      var localStoragePayload = {
        dhan_bot_userID: id
      };
      localStorage.setItem("dhan_bot_userID", JSON.stringify(localStoragePayload));
      var serializedState = localStorage.getItem("dhan_bot_userID");
      return JSON.parse(serializedState);
    }

    return JSON.parse(localStorage.getItem("dhan_bot_userID"));
  } catch (error) {
    throw new Error("Cant set the Local State. Try again", {
      error: error.message
    });
  }
});