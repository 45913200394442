"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "PowerBiReport", {
  enumerable: true,
  get: function get() {
    return _PowerBIReport["default"];
  }
});

var _PowerBIReport = _interopRequireDefault(require("./components/PowerBIReport"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}