"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAllCards = fetchAllCards;
exports.fetchCardsbyTags = fetchCardsbyTags;
exports.fetchAllTags = fetchAllTags;
exports.fetchCardById = fetchCardById;
exports.updateCardById = updateCardById;
exports.createCard = createCard;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error('ServerError');
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error('ApplicationError');

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchAllCards(authToken) {
  var orgId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1234;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responsetemplates?length=0&start=0&org_id=").concat(orgId), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchCardsbyTags(authToken, tags, orgId) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responsetemplates?length=0&start=0&tags=").concat(tags, "&org_id=").concat(orgId), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchAllTags(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/tag?length=0&start=0"), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchCardById(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responsetemplates/").concat(id), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var cardData = _objectSpread(_objectSpread({}, data), {}, {
      adaptiveCardJSON: data.adaptive_card_json,
      sampleDataJSON: data.default_data_json,
      boundData: data.bound_data
    });

    return cardData;
  })["catch"](function (err) {
    return err;
  });
}

function updateCardById(authToken, id, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responsetemplates/").concat(id), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function createCard(authToken, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responsetemplates"), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}