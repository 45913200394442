"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.designer = designer;

var monaco = _interopRequireWildcard(require("monaco-editor"));

var _markdownIt = _interopRequireDefault(require("markdown-it"));

var ACDesigner = _interopRequireWildcard(require("adaptivecards-designer"));

require("adaptivecards-designer/dist/adaptivecards-designer.css");

require("adaptivecards-designer/dist/adaptivecards-defaulthost.css");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var PEP_PUBLIC_PATH = process.env.PEP_PUBLIC_PATH;
PEP_PUBLIC_PATH = PEP_PUBLIC_PATH ? PEP_PUBLIC_PATH : "http://localhost:3000";
window.MonacoEnvironment = {
  getWorkerUrl: function getWorkerUrl(moduleId, label) {
    if (label === "json") {
      return "data:text/javascript;charset=utf-8,".concat(encodeURIComponent("\n\t        importScripts(\"".concat(PEP_PUBLIC_PATH, "/workers/json.worker.js\");")));
    }

    return "data:text/javascript;charset=utf-8,".concat(encodeURIComponent("\n    importScripts(\"".concat(PEP_PUBLIC_PATH, "/workers/editor.worker.js\");")));
  }
};

function designer(_ref) {
  var handleEdit = _ref.handleEdit,
      cardPayload = _ref.cardPayload,
      sampleData = _ref.sampleData,
      _ref$grabDesignerInst = _ref.grabDesignerInstance,
      grabDesignerInstance = _ref$grabDesignerInst === void 0 ? function (designer) {} : _ref$grabDesignerInst; //Configuration options

  ACDesigner.GlobalSettings.enableDataBindingSupport = true; //preview mode

  ACDesigner.GlobalSettings.showSampleDataEditorToolbox = true; //sample data editor

  ACDesigner.GlobalSettings.showTargetVersionMismatchWarning = false; //remove the version warning

  ACDesigner.GlobalSettings.showVersionPicker = false;

  ACDesigner.CardDesigner.onProcessMarkdown = function (text, result) {
    result.outputHtml = new _markdownIt["default"]().render(text);
    result.didProcess = true;
  };

  var hostContainers = [new ACDesigner.WebChatContainer("Bot Framework WebChat", "containers/webchat-container.css")];
  var designer = new ACDesigner.CardDesigner(hostContainers);
  designer.attachTo(document.getElementById("ac-designer"));
  designer.monacoModuleLoaded(monaco);
  designer.treeViewToolbox.collapse(); //to collapse card structure

  designer.jsonEditorToolbox.collapse(); //to collapse card payload editor

  designer._sampleDataEditorToolbox.collapse(); //to collapse sample data editor
  // designer._toolPaletteToolbox.collapse(); //to collapse card elements


  designer._propertySheetToolbox.collapse(); //to collapse element properties
  // designer._copyJSONButton.onClick = () => setPayload(designer.getBoundCard()); //to modify the copyJSONButton if enabled
  // Uncomment this below if you want to run a function on card editor or sample data payload change
  // designer._designerSurface.onLayoutUpdated = (isFullRefresh) => {
  //   if (isFullRefresh) {
  //     designer.scheduleUpdateJsonFromCard();
  //     designer.buildTreeView();
  //     //Add your custom code here, don't change anything above
  //   }
  // };


  designer.targetVersion = designer._hostContainer.targetVersion; // forces the target v1.2 supported by Webchat

  grabDesignerInstance(designer); //sends the designer instance to our react component to make any operations to the SDK directly from the app without making to and fro visits

  if (sampleData || cardPayload) {
    designer.setCard(cardPayload);
    designer.setSampleDataPayload(sampleData);
  }

  document.getElementById("ac-designer").addEventListener("click", function () {
    return handleEdit();
  });
}