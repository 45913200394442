"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchContexts = fetchContexts;
exports.fetchContextById = fetchContextById;
exports.updateContextById = updateContextById;
exports.createContext = createContext;
exports.deleteContext = deleteContext;
exports.fetchKBs = fetchKBs; // const { PEP_NODEJS_API_HOST } = process.env;

var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error('ServerError');
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error('ApplicationError');

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchContexts(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/contexts?length=0&start=0"), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var contextData = data.map(function (context) {
      var id = context.id,
          name = context.name,
          description = context.description,
          UpdatedAt = context.UpdatedAt;
      return {
        name: name,
        description: description,
        id: id,
        UpdatedAt: new Date("".concat(UpdatedAt)).toString()
      };
    });
    return contextData;
  })["catch"](function (err) {
    return err;
  });
}

function fetchContextById(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/contexts/").concat(id), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function updateContextById(authToken, id, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/contexts/").concat(id), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function createContext(authToken, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/contexts"), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
} //


function deleteContext(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/contexts/").concat(id), {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function fetchKBs(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/knowledge_base?length=0&start=0"), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var kbData = data.map(function (kb) {
      var id = kb.id,
          name = kb.name,
          description = kb.description,
          UpdatedAt = kb.UpdatedAt;
      return {
        name: name,
        description: description,
        id: id,
        UpdatedAt: new Date("".concat(UpdatedAt)).toString()
      };
    });
    return kbData;
  })["catch"](function (err) {
    return err;
  });
}