export default function analyticsMenuConfig(data) {
    const { powerBi, switcherItems } = data

    return {
        product: "analytics",
        switcher: {
            items: [
                ...switcherItems
            ],
        },
        aside: {
            items: [...menuItemsGenerator(powerBi),
            {
                title: "Conversation UI",
                root: true,
                icon: "/media/svg/icons/Communication/Chat1.svg",
                page: "/analytics/convoui"
            }
            ],
        },
    };
}

const menuItemsGenerator = (powerBi) => {
    const uniqueCatResponses = powerBi
        .map((item) => item.category)
        .filter((value, index, self) => self.indexOf(value) === index);
    // let uniqueReportNames =
    const analyticats = uniqueCatResponses.map((cat, idx, self) => {
        let menuItem = {
            title: cat,
            root: true,
            icon: "/media/svg/icons/Design/Pixels.svg",
            page: "#",
            subitems: [],
        };

        powerBi.map((item, idx, DataSelf) => {
            if (cat === item.category) {
                let subMenu = {
                    title: item.report_name,
                    root: false,
                    page: `/analytics/report/${item.id}`,
                };
                menuItem.subitems.push(subMenu);
            }
            return item;
        });
        return menuItem;
    });
    return analyticats;
}