/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux"

export function AsideMenuList({ layoutProps }) {
  // const location = useLocation();
  const location = window.location;
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu ? "menu-item-active" : ""} menu-item-open `
      : "";
  };
  const { layout } = useSelector(({ layout }) => ({
    layout: layout
  }), shallowEqual)

  if (layout.menuConfig) {
    const { items } = layout.menuConfig.aside;
    return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {
            items.map((item) => {
              return (
                <li
                  className={`menu-item ${getMenuItemActive(item.page, item.hasOwnProperty('subitems'))}`}
                  aria-haspopup="true"
                >
                  <SideNavLink className="menu-link" isParent={item.subitems && true} url={item.page} href={item.href}>
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(item.icon)}
                      />
                    </span>
                    <span className="menu-text">{item.title}
                      {item.subitems && <span className="svg-icon menu-icon subdrop-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Code/Control.svg")}
                        />
                      </span>}
                    </span>
                  </SideNavLink>
                  {item.subitems && <ul className="submenu-container aside-submenu-hidden" >
                    {item.subitems.map((item) => {
                      return <li className={`menu-item ${getMenuItemActive(item.page, false)}`}>
                        <SideNavLink className="menu-link" isParent={item.subitems && true} url={item.page}>
                          <span className="svg-icon menu-icon">
                            <SVG

                              src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")}
                            />
                          </span>
                          <span className="menu-text">{item.title}</span>
                        </SideNavLink>
                      </li>
                    })}
                  </ul>}
                </li>)

            })
          }
        </ul>
      </>
    );
  } else {
    return (<ul className={`menu-nav ${layoutProps.ulClasses}`}>

    </ul>)
  }

}


const SideNavLink = ({ isParent, children, url, ...props }) => {
  const navLinkRef = React.useRef(null)
  const hrefProps = props.href ? {
    onClick: (e) => {
      window.open(props.href, '_blank').focus();
    },
    to: "#"
  } : {
      to: url
    }
  let iconEl;
  const parentMenuClickHandler = (e) => {
    const navLink = navLinkRef.current;
    const subMenuUlEl = navLink.nextSibling;
    subMenuUlEl && subMenuUlEl.classList.toggle('aside-submenu-hidden');
    iconEl = navLink.querySelector('.subdrop-icon');
    iconEl && iconEl.classList.toggle('active');
  }
  if (isParent) {
    return <NavLink ref={navLinkRef} {...props} to="#" onClick={parentMenuClickHandler}>
      {children}
    </NavLink>
  } else {
    return <NavLink ref={navLinkRef} {...hrefProps} {...props}>
      {children}
    </NavLink>
  }
}