"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAllBots = fetchAllBots;
exports.fetchBotsbyTags = fetchBotsbyTags;
exports.fetchAllTags = fetchAllTags;
exports.fetchBotById = fetchBotById;
exports.fetchAllMessages = fetchAllMessages;
exports.installBot = installBot;
exports.sendBotUrl = sendBotUrl;
exports.createBot = createBot;
var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error("ServerError");
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error("ApplicationError");

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchAllBots(authToken) {
  var orgId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1234;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer?length=0&start=0&org_id=").concat(orgId), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchBotsbyTags(authToken, tags) {
  var orgId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1234;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer?length=0&start=0&tags=").concat(tags, "&org_id=").concat(orgId), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchAllTags(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/tag?length=0&start=0"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchBotById(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer/").concat(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function fetchAllMessages(authToken) {
  var orgId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 11123;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/orgs/").concat(orgId, "/template?start=0&length=0"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function installBot(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer/install_bot/").concat(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function sendBotUrl(authToken) {
  var orgId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 11123;
  var payload = arguments.length > 2 ? arguments[2] : undefined;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/orgs/").concat(orgId, "/messages"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
} // export function updateCardById(authToken, id, payload) {
//   return fetch(`${PEP_NODEJS_API_HOST}/api/responsetemplates/${id}`, {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${authToken}`,
//     },
//     body: JSON.stringify(payload),
//   })
//     .then(handleErrors)
//     .then((res) => {
//       return res.json();
//     })
//     .then((data) => {
//       return data;
//     })
//     .catch((err) => err);
// }
// export function createCard(authToken, payload) {
//   return fetch(`${PEP_NODEJS_API_HOST}/api/responsetemplates`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${authToken}`,
//     },
//     body: JSON.stringify(payload),
//   })
//     .then(handleErrors)
//     .then((res) => {
//       return res.json();
//     })
//     .then((data) => {
//       return data;
//     })
//     .catch((err) => err);
// }


function createBot(authToken, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/bot_composer"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}