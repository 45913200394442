// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";
import { Networkutils } from "../../app/utils/NetworkUtils"
import { GetAllPowerBI_URL } from "../../app/utils/constants"
import analyticsMenuConfig from "../layout/components/header/header-menu/menuconfigs/AnalyticsMenuConfig"

const networkInstance = new Networkutils();

export const actionTypes = {
    SetMenuConfig: "[Set Menu Config to Store] Action",
    RequestPowerBiData: "[PowerBI meta data requested] Action",
    SetPowerBiData: "[Set PowerBI Meta data to store] Action",
    SetGlobalLoading: "[Set Global Loading to store] Action"

};

const initialLayoutState = {
    menuConfig: null,
    powerBi: [],
    globalLoading: false
};
const delay = (ms) => new Promise(res => setTimeout(res, ms))

export const reducer = (state = initialLayoutState, action) => {
    switch (action.type) {
        case actionTypes.SetMenuConfig: {
            const { menuConfig } = action.payload;
            return { ...state, menuConfig: menuConfig };
        }
        case actionTypes.SetPowerBiData: {
            const { powerBi } = action.payload;
            return { ...state, powerBi };
        }
        case actionTypes.SetGlobalLoading: {
            const { val } = action.payload;
            return { ...state, globalLoading: val }
        }
        default:
            return state;
    }
}


export const actions = {
    setGlobalLoading: (val) => ({
        type: actionTypes.SetGlobalLoading,
        payload: { val },
    }),
    setMenuConfig: (menuConfig) => ({
        type: actionTypes.SetMenuConfig,
        payload: { menuConfig },
    }),
    requestPowerBiData: (authToken, switcherItems) => ({
        type: actionTypes.RequestPowerBiData,
        payload: { authToken, switcherItems }
    }),
    setPowerBiData: (powerBi) => ({
        type: actionTypes.SetPowerBiData,
        payload: { powerBi }
    })
};

export function* saga() {
    yield takeLatest(actionTypes.RequestPowerBiData, powerBiDataRequested);
}

function* powerBiDataRequested(action) {
    const { authToken, switcherItems } = action.payload;
    try {
        const powerBi = yield networkInstance.fetchresponse(GetAllPowerBI_URL, "GET", null, networkInstance.getAuthenticationHeaders(authToken))
        const filteredData = powerBi.map(({ id, report_name,
            category,
            dataset_id,
            workspace_id,
            report_id }) => {
            return {
                id,
                report_name,
                category,
                dataset_id,
                workspace_id,
                report_id
            }
        })
        yield put(actions.setPowerBiData(filteredData));
        yield put(actions.setMenuConfig(analyticsMenuConfig({ powerBi: filteredData, switcherItems: switcherItems })))
        yield delay(1000)
        yield put(actions.setGlobalLoading(false));
    } catch (error) {
        console.log(error.message)
    }
}