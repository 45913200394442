"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.actions = exports.reducer = exports.actionTypes = void 0;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var actionTypes = {
  SetAcCard: "[Set AcCards Data to store] Action",
  SetAlert: "[Set Alert to store] Action",
  SetAcCardInputArea: "[Set AcCard input area to store] Action"
};
exports.actionTypes = actionTypes;
var initialAcDebugModuleState = {
  adaptiveCards: [],
  alertTriggered: false,
  adaptiveCardInputArea: "",
  botHostConfig: {
    hostCapabilities: {},
    choiceSetInputValueSeparator: ",",
    supportsInteractivity: true,
    spacing: {
      small: 3,
      "default": 8,
      medium: 20,
      large: 30,
      extraLarge: 40,
      padding: 10
    },
    separator: {
      lineThickness: 1,
      lineColor: "#EEEEEE"
    },
    imageSizes: {
      small: 40,
      medium: 80,
      large: 160
    },
    containerStyles: {
      "default": {
        foregroundColors: {
          "default": {
            "default": "#000000",
            subtle: "#6f6f6f",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          dark: {
            "default": "#000000",
            subtle: "#66000000",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          light: {
            "default": "#646464",
            subtle: "#767676",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          accent: {
            "default": "#0063B1",
            subtle: "#0063B1",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          good: {
            "default": "#028A02",
            subtle: "#DD028A02",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          warning: {
            "default": "#B75C00",
            subtle: "#DDB75C00",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          attention: {
            "default": "#EC130E",
            subtle: "#DDEC130E",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          }
        },
        backgroundColor: "#FFFFFF"
      },
      emphasis: {
        foregroundColors: {
          "default": {
            "default": "#000000",
            subtle: "#6f6f6f",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          dark: {
            "default": "#000000",
            subtle: "#66000000",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          light: {
            "default": "#737373",
            subtle: "#DD737373",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          accent: {
            "default": "#1F6BF1",
            subtle: "#881F6BF1",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          good: {
            "default": "#0D860A",
            subtle: "#DD0D860A",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          warning: {
            "default": "#BD5400",
            subtle: "#DDBD5400",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          },
          attention: {
            "default": "#E31B13",
            subtle: "#DDE31B13",
            highlightColors: {
              "default": "#22000000",
              subtle: "#11000000"
            }
          }
        },
        backgroundColor: "#F9F9F9"
      }
    },
    inputs: {
      label: {
        inputSpacing: 1,
        requiredInputs: {
          size: "Default",
          color: "Default",
          isSubtle: false,
          weight: "Default",
          suffix: " *",
          suffixColor: "Attention"
        },
        optionalInputs: {
          size: "Default",
          color: "Default",
          isSubtle: false,
          weight: "Default"
        }
      },
      errorMessage: {
        size: "Default",
        color: "Attention",
        isSubtle: false,
        weight: "Default"
      }
    },
    actions: {
      maxActions: 100,
      spacing: "Default",
      buttonSpacing: 8,
      showCard: {
        actionMode: "Inline",
        inlineTopMargin: 8,
        style: "emphasis"
      },
      preExpandSingleShowCardAction: false,
      actionsOrientation: "Vertical",
      actionAlignment: "Stretch"
    },
    adaptiveCard: {
      allowCustomStyle: false
    },
    imageSet: {
      maxImageHeight: 100
    },
    media: {
      allowInlinePlayback: true
    },
    factSet: {
      title: {
        size: "Default",
        color: "Default",
        isSubtle: false,
        weight: "Bolder",
        wrap: true
      },
      value: {
        size: "Default",
        color: "Default",
        isSubtle: false,
        weight: "Default",
        wrap: true
      },
      spacing: 8
    },
    alwaysAllowBleed: false,
    _legacyFontType: {
      fontFamily: "Segoe UI,Segoe,Segoe WP,Helvetica Neue,Helvetica,sans-serif",
      fontSizes: {
        small: 12,
        "default": 14,
        medium: 17,
        large: 21,
        extraLarge: 26
      },
      fontWeights: {
        lighter: 200,
        "default": 400,
        bolder: 600
      }
    },
    fontTypes: {
      "default": {
        fontFamily: "Calibri, sans-serif",
        fontSizes: {
          small: 12,
          "default": 14,
          medium: 17,
          large: 21,
          extraLarge: 26
        },
        fontWeights: {
          lighter: 200,
          "default": 400,
          bolder: 600
        }
      },
      monospace: {
        fontFamily: "'Courier New', Courier, monospace",
        fontSizes: {
          small: 12,
          "default": 14,
          medium: 17,
          large: 21,
          extraLarge: 26
        },
        fontWeights: {
          lighter: 200,
          "default": 400,
          bolder: 600
        }
      }
    }
  }
};

var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialAcDebugModuleState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.SetAcCard:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          adaptiveCards: action.payload
        });
      }

    case actionTypes.SetAlert:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          alertTriggered: action.payload
        });
      }

    case actionTypes.SetAcCardInputArea:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          adaptiveCardInputArea: action.payload
        });
      }

    default:
      return state;
  }
};

exports.reducer = reducer;
var actions = {
  setAlert: function setAlert(data) {
    return {
      type: actionTypes.SetAlert,
      payload: data
    };
  },
  setAcCardInputArea: function setAcCardInputArea(AcCardInput) {
    return {
      type: actionTypes.SetAcCardInputArea,
      payload: AcCardInput
    };
  },
  setAcCard: function setAcCard(AcCardArr) {
    return {
      type: actionTypes.SetAcCard,
      payload: AcCardArr
    };
  }
};
exports.actions = actions;
var _default = {
  actionTypes: actionTypes,
  actions: actions,
  reducer: reducer
};
exports["default"] = _default;