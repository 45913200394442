import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest} from '../../utils/authConfig';

export default class MSALAuthUtil{
  constructor(){
      this.msalInstance = new PublicClientApplication(msalConfig);
      this.accessToken = null;
      this.account=null;
      this.sastoken='';
  }
  getAllAccounts(){
      return this.msalInstance.getAllAccounts();
  }
  setActiveAccount(account){
      if(!account){
          return false
      }
      this.msalInstance.setActiveAccount(account);
      return true
  }
  signIn(){
      this.msalInstance.loginRedirect(loginRequest);
  }
  handleRedirectResponse(response) {
      if (response) {
          // if response contains an access token, store it
          if (response.accessToken && response.accessToken !== "") {
              this.accessToken = response.accessToken;
              this.account = response.account;
              this.setActiveAccount(response.account)
          }
      }
  }
  getAccessToken(){
      return this.accessToken
  }
  getAccount(){
      return this.account
  }
  getSasToken(){
    return this.sastoken
  }
}