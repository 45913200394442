import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector, shallowEqual } from "react-redux"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Link } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    root: {
        width: 250,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto"
    },
    iconSize: {
        fontSize: "1.8rem"
    },
    listItem: {
        "&:hover": {
            "& $iconSize": {
                color: "#fff"
            }
        }
    }
}));



export default function SwitcherList({ closePopoverHandler }) {
    const classes = useStyles();
    const { layout , convoUiDebugModule } = useSelector(({ layout, convoUiDebugModule }) => ({
        layout: layout,
        convoUiDebugModule
    }), shallowEqual)
    const { items } = layout.menuConfig && layout.menuConfig.switcher;
    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
                {items.map((item) => {
                    return <Link to={item.page}>
                        <ListItem onClick={closePopoverHandler} className={classes.listItem} button>

                            <ListItemIcon>
                                <SVG
                                    src={toAbsoluteUrl(item.icon)}
                                />
                                {/* <item.muicon className={classes.iconSize} /> */}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    </Link>

                })}
                {!items.length && <ListItem disabled button>
                    <ListItemText className="text-muted" primary="No Products" />
                </ListItem>}

            </List>

        </div>
    );
}