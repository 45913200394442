import { LogLevel } from "@azure/msal-browser";
// Config object to be passed to Msal on creation
const { CLIENTID, SIGNIN_AUTHORITY, PASSWORD_RESET_AUTHORITY, AUTHORITY_DOMAIN, REDIRECT_URL, SCOPES, SIGNIN_POLICY_NAME, PASSWORD_RESET_POLICY_NAME, EDIT_PROFILE,EDIT_PROFILE_AUTHORITY  } = process.env;
export const b2cPolicies = {
    names: {
      signUpSignIn: SIGNIN_POLICY_NAME,
      forgotPassword: PASSWORD_RESET_POLICY_NAME,
      editProfile: EDIT_PROFILE
    },
    authorities: {
        signUpSignIn: {
            authority:
                SIGNIN_AUTHORITY,
        },
        forgotPassword: {
            authority:
                PASSWORD_RESET_AUTHORITY,
        },
        editProfile: {
          authority: EDIT_PROFILE_AUTHORITY,
        }
    },
    authorityDomain: AUTHORITY_DOMAIN,
};

export const msalConfig = {
    auth: {
        clientId: CLIENTID, // This is the ONLY mandatory field; everything else is optional.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: REDIRECT_URL, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// scopes can be derived from env
export const loginRequest = {
    scopes: SCOPES ? SCOPES.split(',') : []
};

// Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
//     graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
// };
