"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchKBs = fetchKBs;
exports.fetchResponses = fetchResponses;
exports.fetchKbById = fetchKbById;
exports.updateKbById = updateKbById;
exports.createKb = createKb;
exports.deleteKB = deleteKB;
var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error("ServerError");
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error("ApplicationError");

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchKBs(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/knowledge_base?length=0&start=0"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var kbData = data.map(function (kb) {
      var id = kb.id,
          name = kb.name,
          description = kb.description,
          UpdatedAt = kb.UpdatedAt;
      return {
        name: name,
        description: description,
        id: id,
        UpdatedAt: new Date("".concat(UpdatedAt)).toString()
      };
    });
    return kbData;
  })["catch"](function (err) {
    return err;
  });
}

function fetchResponses(authToken) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responses/?start=0&length=0"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var responsesData = data.map(function (response) {
      var name = response.name,
          id = response.id,
          sample = response.sample,
          template = response.template,
          adaptivecard_json = response.adaptivecard_json;
      return {
        id: id,
        name: name,
        sample: sample,
        template: template,
        adaptivecard_json: adaptivecard_json
      };
    });
    return responsesData;
  })["catch"](function (err) {
    return err;
  });
}

function fetchKbById(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/knowledge_base/").concat(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function updateKbById(authToken, id, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/knowledge_base/").concat(id), {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function createKb(authToken, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/knowledge_base"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err.json().then(function (err_data) {
      return err_data;
    });
  });
} //


function deleteKB(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/knowledge_base/").concat(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err.json().then(function (err_data) {
      return err_data;
    });
  });
}