import React, { useEffect } from "react";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import pepMenuConfig from "../components/header/header-menu/menuconfigs/PepMenuConfig"
import defaultMenuConfig from "../components/header/header-menu/menuconfigs/DefaultMenuConfig"

import { actions } from "../../_redux/layoutRedux"
import { useSelector, shallowEqual } from "react-redux"
import { useDispatch } from "react-redux";
import {
  useLocation
} from "react-router-dom";
const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#17c191",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3783e7",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f018a6",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const dispatch = useDispatch()
  const { authToken, switcherItems, globalLoading, menuConfig, convoUiDebugModule } = useSelector(({ auth, layout, convoUiDebugModule }) => ({
    authToken: auth.authToken,
    switcherItems: auth.switcherItems,
    menuConfig: layout.menuConfig,
    globalLoading: layout.globalLoading,
    convoUiDebugModule
  }), shallowEqual)
  let location = useLocation();
  useEffect(() => {

    switch (true) {
      case location.pathname.indexOf('/pep/') > -1: {
        if (!menuConfig || menuConfig.product !== "pep") {
          dispatch(actions.setGlobalLoading(true))
          dispatch(actions.setMenuConfig(pepMenuConfig({ switcherItems })))
          setTimeout(() => {
            dispatch(actions.setGlobalLoading(false))

          }, 2000);
        }
      }
        break;
      case location.pathname.indexOf('/analytics/') > -1: {
        if (!menuConfig || menuConfig.product !== "analytics") {
          dispatch(actions.setGlobalLoading(true))
          dispatch(actions.requestPowerBiData(authToken, switcherItems))
        }
      }
        break;
        case location.pathname.indexOf('/analytics/') > -1: {
          dispatch(actions.convoUiDebugModule())

        }
      default:
        dispatch(actions.setMenuConfig(defaultMenuConfig({ switcherItems })))
        break;
    }
    // dispatch(actions.setMenuConfig(pepMenuConfig(null)))
  }, [location.pathname])
  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");
    // Show SplashScreen
    if (splashScreen && globalLoading) {
      splashScreen.classList.remove("hidden");
      return () => {
        splashScreen.classList.add("hidden");
      };
    }
    let timeout;
    if (splashScreen && !globalLoading) {
      timeout = setTimeout(() => {
        splashScreen.classList.add("hidden");
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [globalLoading])

  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
