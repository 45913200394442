import React from 'react';
import Header from '../../../../components/Header/Header';
import './Homepage.scss'

export default function Homepage() {

  return (<div className='homepage-container'>
    <Header />


    <div className="homepage-content">
      <div className="homepage-content-aisolutions-header">
        <span>AI Solutions for Healthcare</span>
      </div>
      <div className="homepage-content-aisolutions-description">
        <span>
          We build innovative AI solutions for healthcare. We partner with
          hospitals, health insurers, specialty groups and life sciences
          companies to bring the latest AI technologies to solve your unique
          problems.
        </span>
      </div>

    </div>
  </div>)
}