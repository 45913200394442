export default function pepMenuConfig(data) {
    const { switcherItems } = data
    // let subMenu = {
    //     title: item.report_name,
    //     root: false,
    //     page: `/app/dica/${item.id}`,
    //   };
    return {
        product: "pep",
        switcher: {

            items: [
                ...switcherItems

            ],
        },
        aside: {
            items: [{
                title: "Bot Gallery",
                root: true,
                icon: "/media/svg/icons/Communication/bot-gallery-icon.svg",
                page: "/pep/botgallery",
            }, {
                title: "My Bot Gallery",
                root: true,
                icon: "/media/svg/icons/Communication/MyBotGallery-icon.svg",
                page: "/pep/mybots",
            },
            {
                title: "Knowledge Bases",
                root: true,
                icon: "/media/svg/icons/Home/Library.svg",
                page: "/pep/knowledge-bases",
            }, {
                title: "Responses",
                root: true,
                icon: "/media/svg/icons/Communication/Chat6.svg",
                page: "/pep/responses",

            }, {
                title: "Analytics",
                root: true,
                icon: "/media/svg/icons/Devices/Display1.svg",
                href: "https://www.dhan.ai/app/dica/1139",
            }, {
                title: "Cards Gallery",
                root: true,
                icon: "/media/svg/icons/Design/Substract.svg",
                page: "/pep/cardgallery",
            }, {
                title: "My Card Gallery ",
                root: true,
                icon: "/media/svg/icons/myCardGalleryIcon.svg",
                page: "/pep/mycards",
            }, {
                title: "New Card",
                root: true,
                icon: "/media/svg/icons/Communication/Chat5.svg",
                page: "/pep/cardgallery/create",

            }, 
            // {
            //     title: "New Bot",
            //     root: true,
            //     icon: "/media/svg/icons/Communication/NewBot-icon.svg",
            //     page: "/pep/botgallery/create",
            // }, 
            {
                title: "AC Debug Tool",
                root: true,
                icon: "/media/svg/icons/Tools/Tools.svg",
                page: "/pep/acDebugTool",

            },
            {
                title: "Bot Debug Tool",
                root: true,
                icon: "/media/svg/icons/Tools/Tools.svg",
                page: "/pep/botdebug",

            },
             {
                title: "Messages",
                root: true,
                icon: "/media/svg/icons/Communication/sendmail.svg",
                page: "/pep/messages",

            }
            ],
        },
    };
}
