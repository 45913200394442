import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetAllowedRoutes: "[Set Routes] Roll Based",
  SetSwitcherItems: "[Set Switcher] Roll Based",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  sasToken: undefined,
  isSessionActive: undefined,
  allowedRoutes: [],
  switcherItems: [],
};

export const reducer = persistReducer(
  {
    storage, key: "v711-demo1-auth", whitelist: ["user", "authToken", "sasToken", "allowedRoutes",
      "switcherItems",]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user, sasToken } = action.payload;

        return { ...state, authToken, user, sasToken, isSessionActive: true };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.SetAllowedRoutes: {
        const { routes } = action.payload;

        return { ...state, allowedRoutes: routes };
      }
      case actionTypes.SetSwitcherItems: {
        const { items } = action.payload;

        return { ...state, switcherItems: items };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, user, sasToken) => ({
    type: actionTypes.Login,
    payload: { authToken, user, sasToken },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  setRoutes: (routes) => ({
    type: actionTypes.SetAllowedRoutes,
    payload: { routes },
  }),
  setSwitcher: (items) => ({
    type: actionTypes.SetSwitcherItems,
    payload: { items },
  }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();

  //   yield put(actions.fulfillUser(user));
  // });
}
