"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AlertMessage", {
  enumerable: true,
  get: function get() {
    return _Alert["default"];
  }
});

var _Alert = _interopRequireDefault(require("./components/Alert"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}