"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BotDebugTool", {
  enumerable: true,
  get: function get() {
    return _BotDebug.DebugTool;
  }
});
Object.defineProperty(exports, "botDebugRedux", {
  enumerable: true,
  get: function get() {
    return _debugToolRedux["default"];
  }
});

var _BotDebug = require("./components/BotDebug");

var _debugToolRedux = _interopRequireDefault(require("./_redux/debugToolRedux"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}