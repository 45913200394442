export default class SessionUtils {
  constructor(hours) {
    this.hours = hours;
    this.expiry = null;
    this.currentTime = new Date();
  }
  static getSessionExpiry = () => {
    try {
      const serializedState = localStorage.getItem("sessionExpiry");

      if (serializedState === null) {
        return undefined;
      }

      return JSON.parse(serializedState);
    } catch (error) {
      return undefined;
    }
  };
  static sessionExpired = () => {
    const currentTime = new Date().getTime();
    if (currentTime >= this.getSessionExpiry()) {
      return true;
    }
    return false;
  };
  setSessionExpiry = () => {
    try {
      this.expiry = this.currentTime.setHours(
        this.currentTime.getHours() + this.hours
      );
      if (this.expiry) {
        const serializedState = JSON.stringify(this.expiry);
        localStorage.setItem("sessionExpiry", serializedState);
      }
    } catch (error) {
      // Ignore write errors.
      throw new Error(error);
    }
  };
}
