import {
  REQUEST_PASSWORD_URL,
  LOGIN_URL,
  REGISTER_URL,
  SAS_URL,
} from "../../../utils/constants";

export function login(email, password) {
  return fetch(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Login Failed");
    }
    return res.json();
  });
}
export function fetchSasToken(authToken) {
  const startTime = new Date().getTime();
  return fetch(SAS_URL + `?st=${startTime}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error("SAS Failed");
    }
    return res.json();
  });
}

export function register(email, firstName, lastName, username, password) {
  return fetch(REGISTER_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, firstName, lastName, username, password }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Registration Failed");
    }
    return res.json();
  });
}

export function requestPassword(email) {
  return fetch(REQUEST_PASSWORD_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Reset password Failed");
    }
    return res.json();
  });
}

// export function fetchRoleComponents(authToken, user) {
//   const usergroups = user.idTokenClaims ? user.idTokenClaims.userGroups.join(',') : ''
//   const products = user.idTokenClaims ? user.idTokenClaims.products.join(',') : ''
//   return fetch(`${ROLECOMPONENTS_URL}?lenght=0&start=0&usergroups=${usergroups}&products=${products}`, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + authToken,
//     },
//   }).then((res) => {
//     if (!res.ok) {
//       throw new Error("Request Failed");
//     }
//     return res.json();
//   });
// }

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   return axios.get(ME_URL);
// }
