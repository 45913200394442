"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Dashboard", {
  enumerable: true,
  get: function get() {
    return _Dashboard["default"];
  }
});
Object.defineProperty(exports, "KnowledgeBaseCRU", {
  enumerable: true,
  get: function get() {
    return _CreateReadOrUpdateKB["default"];
  }
});
Object.defineProperty(exports, "kbRedux", {
  enumerable: true,
  get: function get() {
    return _kbRedux["default"];
  }
});

var _Dashboard = _interopRequireDefault(require("./components/Dashboard"));

var _CreateReadOrUpdateKB = _interopRequireDefault(require("./components/CreateReadOrUpdateKB"));

var _kbRedux = _interopRequireDefault(require("./_redux/kbRedux"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}