import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import MSALAuthUtil from "../../MSALAuth/authMethods"
const msalAuth = new MSALAuthUtil()
class Logout extends Component {
  componentDidMount() {
    if(this.props.location.pathname.indexOf('success') > -1){
      this.props.logout();
    }else{
    this.msalLogoutHandler()

    }
  }
  msalLogoutHandler = async () => {
    await msalAuth.msalInstance.logoutRedirect({
    postLogoutRedirectUri: `${process.env.PEP_PUBLIC_PATH}/logout/success`
  });
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
