"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchResponses = fetchResponses;
exports.fetchResponseById = fetchResponseById;
exports.updateResponseById = updateResponseById;
exports.createResponse = createResponse;
exports.deleteResponse = deleteResponse;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error("ServerError");
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error("ApplicationError");

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchResponses(authToken) {
  //Under Construction
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responses?length=0&start=0"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var responseData = data.map(function (response) {
      var id = response.id,
          name = response.name,
          description = response.description,
          sample = response.sample,
          status = response.status,
          adaptivecard_json = response.adaptivecard_json,
          default_data_json = response.default_data_json;
      return {
        id: id,
        name: name,
        description: description,
        sample: sample,
        status: status,
        adaptivecard_json: adaptivecard_json,
        default_data_json: default_data_json
      };
    });
    return responseData;
  })["catch"](function (err) {
    return err;
  });
}

function fetchResponseById(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responses/").concat(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    var responseData = _objectSpread(_objectSpread({}, data), {}, {
      adaptiveCardJSON: data.adaptivecard_json,
      sampleDataJSON: data.default_data_json,
      boundData: data.bound_data
    });

    return responseData;
  })["catch"](function (err) {
    return err;
  });
}

function updateResponseById(authToken, id, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responses/").concat(id), {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function createResponse(authToken, payload) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responses"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function deleteResponse(authToken, id) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/responses/").concat(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}