export default function pepMenuConfig(data = null) {
    const { switcherItems } = data;
    // let subMenu = {
    //     title: item.report_name,
    //     root: false,
    //     page: `/app/dica/${item.id}`,
    //   };
    return {
        product: "none",
        switcher: {
            items: [...switcherItems],
        },
        aside: {
            items: [{
                title: "Dashboard",
                root: true,
                icon: "/media/svg/icons/Design/Substract.svg",
                page: "/default/dashboard",
            },],
        },
    };
}
