import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { gridRedux } from "@pep/frontend-datagrid-module";
import { kbRedux } from "@pep/frontend-kb-module";
import { contextRedux } from "@pep/frontend-context-module";
import { responsesRedux } from "@pep/frontend-response-module";
import { cardsRedux } from "@pep/frontend-card-gallery";
import { botRedux } from "@pep/frontend-bot-gallery-module";
import { acDebugRedux } from "@pep/frontend-acdebug-module";
import { messagesRedux } from "@pep/frontend-messages-module"
import { reducer as LayoutReducer, saga as LayoutSaga } from "../_metronic/_redux/layoutRedux"
import { myBotRedux } from "@pep/frontend-mybots-module"
import { mycardsRedux } from "@pep/frontend-mycards-module"
import {botDebugRedux} from "@pep/frontend-bot-debugtool-module"
import {convoUiToolRedux} from '@pep/analytics-conversation-ui-module'
export const rootReducer = combineReducers({
  auth: auth.reducer,
  dataGridModule: gridRedux.reducer,
  kbModule: kbRedux.reducer,
  contextModule: contextRedux.reducer,
  responsesModule: responsesRedux.reducer,
  cardsModule: cardsRedux.reducer,
  botsModule: botRedux.reducer,
  acDebugModule: acDebugRedux.reducer,
  layout: LayoutReducer,
  messagesModule: messagesRedux.reducer,
  myBotsModule: myBotRedux.reducer,
  myCardsModule: mycardsRedux.reducer,
  botDebugModule: botDebugRedux.reducer,
  convoUiDebugModule: convoUiToolRedux.reducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    gridRedux.saga(),
    kbRedux.saga(),
    contextRedux.saga(),
    responsesRedux.saga(),
    cardsRedux.saga(),
    botRedux.saga(),
    LayoutSaga(),
    messagesRedux.saga(),
    myBotRedux.saga(),
    mycardsRedux.saga(),
    botDebugRedux.saga(),
    convoUiToolRedux.saga()
  ]);
}
