import React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {b2cPolicies} from '../../utils/authConfig';

import {MSAL_PWRESET_ERROR} from '../../utils/constants';

import {CustomNavigationClient} from '../../utils/NavClient';
import  * as auth from '../Auth/_redux/authRedux';
import {actions} from '../../../_metronic/_redux/layoutRedux';
import MSALAuthUtil from './authMethods';
import SessionUtils from '../../utils/session_utils';
import {fetchRoleComponents, fetchSasToken} from '../Auth/_redux/authCrud';

export default function MSALAuth(props) {

  const msalAuth = new MSALAuthUtil()
  const history = useHistory();
  const dispatch = useDispatch()
  const { isAuthorized, globalLoading } = useSelector(
    ({ auth, layout }) => ({
      isAuthorized: auth.user != null,
      globalLoading: layout.globalLoading
    }),
    shallowEqual
  );
  const navigationClient = new CustomNavigationClient(history);
  msalAuth.msalInstance.setNavigationClient(navigationClient);


  React.useEffect(() => {
    dispatch(actions.setGlobalLoading(true))

    if (!window.location.hash && !isAuthorized) {
      msalAuth.signIn()
    }
    handleRedirection()
    return () => dispatch(actions.setGlobalLoading(false))
  }, [])

  React.useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");
    // Show SplashScreen
    if (splashScreen && globalLoading) {
      splashScreen.classList.remove("hidden");
      return () => {
        splashScreen.classList.add("hidden");
      };
    }
    let timeout;
    if (splashScreen && !globalLoading) {
      timeout = setTimeout(() => {
        splashScreen.classList.add("hidden");
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [globalLoading])
  const handleRedirection = async () => {
    try {
      const res = await msalAuth.msalInstance.handleRedirectPromise()
      msalAuth.handleRedirectResponse(res)
      

      if (msalAuth.getAccessToken()) {
        // const allowedRoutes = await fetchRoleComponents(msalAuth.getAccessToken(), msalAuth.getAccount());
        // const powerbiMetadata = await fetchPowerbiMetaData(msalAuth.getAccessToken())
        // const sasToken = await fetchSasToken(msalAuth.getAccessToken())
        

        const setExpiry = new SessionUtils(1);
        setExpiry.setSessionExpiry();
        history.push({
          pathname: '/auth/success',
          postLoginData: {
            accessToken: msalAuth.getAccessToken(),
            user: msalAuth.getAccount(),
            // sasToken: sasToken,
            
            // allowedRoutes: allowedRoutes,
            // powerbiMetadata: powerbiMetadata
          }
        })
      }
      

    } catch (error) {
      if (error.errorMessage.indexOf("Access token entity is null") > -1) {
        dispatch(auth.actions.logout())
        localStorage.clear();
        sessionStorage.clear();
        history.push("/")
      }
      if (error.errorMessage.indexOf(MSAL_PWRESET_ERROR) > -1) {
        try {
          msalAuth.msalInstance.loginRedirect(b2cPolicies.authorities.passwordReset);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }


  if (isAuthorized) {
    return <Redirect to="/auth/success" />
  }
  return (
    <></>
  )
}