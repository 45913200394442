"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AcDebugTool", {
  enumerable: true,
  get: function get() {
    return _Bot["default"];
  }
});
Object.defineProperty(exports, "acDebugRedux", {
  enumerable: true,
  get: function get() {
    return _acDebugRedux["default"];
  }
});

var _Bot = _interopRequireDefault(require("./components/Bot"));

var _acDebugRedux = _interopRequireDefault(require("./_redux/acDebugRedux"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}