import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WebChat } from "@pep/frontend-webchat-module";

let { PEP_NODEJS_API_HOST, PEP_LOCAL_API_HOST } = process.env;

PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST
  ? PEP_NODEJS_API_HOST
  : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

export default function WebChatPreview(props) {
  const webChatPreviewType = props.previewType;
  const params = new URLSearchParams(props.location.search);
  const authToken = props.authToken;
  const id = params.get("vaid");
  const [contextName, setName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (webChatPreviewType && webChatPreviewType === "bot") {
      fetchData();
    }
  }, [webChatPreviewType]);
  async function fetchData(previewType) {
    const endPointContext = `${PEP_NODEJS_API_HOST}/api/contexts/${id}`;
    const endPointBot = `${PEP_NODEJS_API_HOST}/api/bot_composer/${id}`;
    const apiEndpoint =
      webChatPreviewType === "bot" ? endPointBot : endPointContext;
    await fetch(apiEndpoint, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (webChatPreviewType === "bot") {
          setName(res.bot_name);
        } else {
          setName(res.name);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  return loading ? (
    <div className="webchat-loading">
      <CircularProgress color="inherit" />
    </div>
  ) : (
      <>
        <div className="heading webchat-preview">
          Previewing Webchat for {capitalize(webChatPreviewType)}: {contextName}
        </div>
        <WebChat fhirLaunchUrl={props.fhirLaunchURL || ""} />
      </>
    );
}
