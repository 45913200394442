import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import {toAbsoluteUrl} from '../../../_metronic/_helpers'
import "./Header.styles.scss";


export default function Header() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
        isAuthorized: auth.user != null,
    }),
    shallowEqual
);

return(
  <div className="homepage-header">
    <div className="homepage-header-logo">
      <img src={toAbsoluteUrl('/media/logos/logo.svg')} alt="brand-logo"  />
    </div>
    {
      !isAuthorized ? <Button variant='contained' className='header-button' component={Link} to='/auth/login' disableRipple>
      Sign In
    </Button> : <Button className='header-button' variant='contained' component={Link} to='/pep' disableRipple>
      Dashboard
    </Button>
    }
  </div>
)
}