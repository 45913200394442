import React, { Suspense } from 'react'
import { Redirect, Route, Switch, } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";

export default function DefaultRoutes({ component }) {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact={true} from="/" to="/default/dashboard" />
                <Route path="/default/dashboard" component={component} />
                <Redirect to="/error/error-v1" />
            </Switch>
        </Suspense>
    )
}
