import React from 'react';
import { useLocation, useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux';
import * as auth from "../Auth/_redux/authRedux";
import {actions as layoutActions} from "../../../_metronic/_redux/layoutRedux";
import { switcherConfig,rolesConfig } from "../../utils/rbac";

export default function PostAuth(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch()
  React.useEffect(() => {
      const { postLoginData } = location;

      
      if (postLoginData) {
          const userGroups = postLoginData.user.idTokenClaims ? postLoginData.user.idTokenClaims.userGroups : [];
          // let allowedRoutes = postLoginData.allowedRoutes;
          const { switcherItems, allowedRoutes } = getSwitcherAndAllowedRoutes(userGroups)
        
          // dispatch(layoutActions.normalizePowerBiData(postLoginData.powerbiMetadata,switcherItems))
          dispatch(auth.actions.setRoutes(allowedRoutes))
          dispatch(auth.actions.setSwitcher(switcherItems))
          dispatch(auth.actions.login(postLoginData.accessToken, postLoginData.user))
          if (postLoginData.user.idTokenClaims && postLoginData.user.idTokenClaims.products && postLoginData.user.idTokenClaims.products.length) {
              history.push(`/${postLoginData.user.idTokenClaims.products[0]}`)
          } else {
              history.push('/')
          }
      } else {
          history.push('/')
      }
  }, [])
  return (
      <div>
          hello
      </div>
  )
}

//Post-Auth Local Utils
const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
      newArray.push(lookupObject[i]);
  }
  return newArray;
};
const getSwitcherAndAllowedRoutes = (userGroups) => {
  let switcherItems = [];
  let allowedRoutes = [];
  //allowedRoutes are included in id token claims, so this function returns only switcherItems
  if (userGroups.length) {
      allowedRoutes = userGroups.reduce((acc, role) => {
          if (rolesConfig.hasOwnProperty(role)) {
              return [...acc, ...rolesConfig[role].routes];
          }
          return [...acc]
      }, []);
      switcherItems = userGroups.reduce((acc, role) => {
          if (switcherConfig.hasOwnProperty(role)) {
              return [...acc, ...switcherConfig[role].submenu];
          }
          return [...acc]

      }, []);
      switcherItems = removeDuplicates(switcherItems, "page");
      allowedRoutes = removeDuplicates(allowedRoutes, "url");
  }

  // return switcherItems
  return { switcherItems, allowedRoutes }
}