"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDebugData = fetchDebugData;
var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error("Server Error");
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error("Application Error");

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchDebugData(authToken, queryParams) {
  var botId = queryParams.botId,
      orgId = queryParams.orgId,
      convoId = queryParams.convoId;
  var url = "".concat(PEP_NODEJS_API_HOST, "/api/get-convo?botId=").concat(botId, "&orgId=").concat(orgId, "&convoId=").concat(convoId);
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (response) {
    return response.json();
  })["catch"](function (err) {
    return err;
  });
}