export const host = process.env.PEP_NODEJS_API_HOST;

export const LOGIN_URL = `${host}/api/login`;

export const REGISTER_URL = `${host}/api/register`;
export const SAS_URL = `${host}/api/bot_composer/sas/token`;
export const REQUEST_PASSWORD_URL = `${host}/api/forget-password`;

export const MOCK_FHIR_CONFIG = {
  iss:
    "https://launch.smarthealthit.org/v/r3/sim/eyJoIjoiMSIsImIiOiJzbWFydC0xNjQyMDY4IiwiZSI6InNtYXJ0LVByYWN0aXRpb25lci03MTYxNDUwMiJ9/fhir",
  redirectUri: window.location.pathname,
  clientId: "whatever",
  scope: "launch/patient offline_access openid fhirUser",
  state: "abc123",
};
export const MOCK_FHIR_LAUNCH_URL = `${window.location.origin}/fhir`;

//PowerBI 
export const GetAllPowerBI_URL = `${host}/api/powerbi?lenght=0&start=0`
 
export const MSAL_PWRESET_ERROR = 'AADB2C90118'
