import React, { Suspense } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import * as pepExports from "./ModuleExports";
import { useSelector, shallowEqual } from "react-redux"
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import WebChatPreview from "../../pages/WebChatPreview";
import { MOCK_FHIR_CONFIG, MOCK_FHIR_LAUNCH_URL } from "../../utils/constants";
import { FhirAuth } from "@pep/frontend-webchat-module";
import DefaultRoutes from "../DefaultRoutes"
import store from "../../../redux/store";

function Demo2Dashboard() {
  return <>No Products found.</>;
}

export default function PepRoutes(props) {
  const history = useHistory();
  const { authToken, allowedRoutes } = useSelector(({ auth }) => ({
    authToken: auth.authToken,
    allowedRoutes: auth.allowedRoutes
  }), shallowEqual)
  if (allowedRoutes.length) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Redirect exact={true} from="/pep" to="/pep/dashboard" />

          <ContentRoute path="/pep/dashboard" component={pepExports["Dashboard"]} />
          {allowedRoutes.map((route) => {
            if (route.component === "WebChatPreview") {
              return <Route
                exact
                path={`${route.url}`}
                component={(props) => (
                  <WebChatPreview
                    fhirLaunchURL={MOCK_FHIR_LAUNCH_URL}
                    {...props}
                    authToken={authToken}
                    previewType="bot"
                  />
                )}
              />
            }
            if (route.component === "FhirAuth") {
              return <Route
                path={`${route.url}`}
                exact
                component={() => (
                  <FhirAuth
                    smartConfig={MOCK_FHIR_CONFIG}
                    history={history}
                    authToken={authToken}
                  />
                )}
              />
            }
            return (
              <ContentRoute
                exact={true}
                path={`${route.url}`}
                component={pepExports[route.component]}
                authToken={authToken}
                history={history}
                store={store}
              />
            );

          })}
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <DefaultRoutes component={Demo2Dashboard} />
    );
  }
}

