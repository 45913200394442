"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchPowerReport = void 0;
var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error("ServerError");
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error("ApplicationError");

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

var fetchPowerReport = function fetchPowerReport(authToken, id) {
  // http://localhost:5000/api/powerbi/embed/1234/report
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/powerbi/embed/").concat(id, "/report"), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
};

exports.fetchPowerReport = fetchPowerReport;