"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Launcher = require("./Launcher");

Object.keys(_Launcher).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Launcher[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Launcher[key];
    }
  });
});

var _BotContainer = require("./BotContainer");

Object.keys(_BotContainer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _BotContainer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _BotContainer[key];
    }
  });
});

var _WelcomeScreen = require("./WelcomeScreen");

Object.keys(_WelcomeScreen).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _WelcomeScreen[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _WelcomeScreen[key];
    }
  });
});

var _BotBody = require("./BotBody");

Object.keys(_BotBody).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _BotBody[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _BotBody[key];
    }
  });
});

var _BotHeader = require("./BotHeader");

Object.keys(_BotHeader).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _BotHeader[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _BotHeader[key];
    }
  });
});