import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Popover from '@material-ui/core/Popover';



import SwitcherList from "./SwitcherList"

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    switchTo: {
        color: "#6c7293",
        '&:hover': {
            color: "#0073e9"
        },
    },
    switchIcon: {
        marginRight: theme.spacing(2)
    }
}));

export default function SwitcherMenu() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <span aria-describedby={id} variant="contained" className={`${classes.switchTo} menu-link`} onClick={handleClick}>
                <i className={`${classes.switchIcon} flaticon-squares`} /> <span className="menu-text">Switch To ...</span>
            </span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <SwitcherList closePopoverHandler={handleClose} />
            </Popover>
        </>
    );
}