// component's config object.
const components = {
    BotGallery: {
        component: "BotGallery",
        url: "/pep/botgallery",
        title: "Bot Gallery - Bot Gallery Module",
        module: 1,
    },
    // NewBot: {
    //     component: "NewBot",
    //     url: "/pep/botgallery/create",
    //     title: "New Bot - Bot Gallery Module",
    //     module: 1,
    // },
    BotDetails: {
        component: "BotDetails",
        url: "/pep/botgallery/:id?",
        title: "Bot Details - Bot Gallery Module",
        module: 1,
    },
    KBDashboard: {
        component: "KBDashboard",
        url: "/pep/knowledge-bases",
        title: "KB Dashboard - KB Module",
        module: 1,
    },
    KnowledgeBaseCRU: {
        component: "KnowledgeBaseCRU",
        url: "/pep/knowledge-bases/:id?",
        title: "KB CRU - KB Module",
        module: 1,
    },
    ResponsesDashboard: {
        component: "ResponsesDashboard",
        url: "/pep/responses",
        title: "Responses Dashboard - Responses Module",
        module: 1,
    },
    CRUResponse: {
        component: "CRUResponse",
        url: "/pep/responses/:id?",
        title: "Responses CRU - Responses Module",
        module: 1,
    },
    CardGallery: {
        component: "CardGallery",
        url: "/pep/cardgallery",
        title: "Card Gallery - CardGallery Module",
        module: 1,
    },
    CRUCard: {
        component: "CRUCard",
        url: "/pep/cardgallery/:id?",
        title: "Card CRU - CardGallery Module",
        module: 1,
    },    
    MyCardGallery: {
        component: "MyCardGallery",
        url: "/pep/mycards",
        title: "My Card Gallery - MyCardGallery Module",
        module: 1,
    },
    CRUMyCard: {
        component: "CRUMyCard",
        url: "/pep/mycards/:id?",
        title: "MyCard CRU - MyCardGallery Module",
        module: 1,
    },
    WebChatPreview: {
        component: "WebChatPreview",
        url: "/pep/botgallery/preview/:id",
        title: "WebChat Preview - WebChat Module",
        module: 1,
    },
    ShareBotLink: {
        component: "ShareBotLink",
        url: "/pep/sharebotlink",
        title: "ShareBotLink - BotGallery Module",
        module: 1,
    },
    FhirAuth: {
        component: "FhirAuth",
        url: "/pep/fhir",
        title: "Fhir Auth - WebChat Module",
        module: 1,
    },
    AcDebugTool: {
        component: "AcDebugTool",
        url: "/pep/acdebugtool",
        title: "AcDebugTool - AC Module",
        module: 1,
    },
    BotDebugTool: {
        component: "BotDebugTool",
        url: "/pep/botdebug",
        title: "BotDebugTool - Bot-debug-tool Module",
        module: 1,
    },
    MessagesDashboard: {
        component: "MessagesDashboard",
        url: "/pep/messages",
        title: "MessagesDashboard - Messages Module",
        module: 1,
    },
    MessagesCRU: {
        component: "MessagesCRU",
        url: "/pep/messages/:id?",
        title: "MessagesCRU - Messages Module",
        module: 1,
    },
    MyBotsGallery: {
        component: "MyBotGallery",
        url: "/pep/mybots",
        title: "MyBotGallery - MyBots Module",
        module: 1,
    },
    MyBotDetails: {
        component: "MyBotDetails",
        url: "/pep/mybots/:id?",
        title: "MyBotDetails - MyBots Module",
        module: 1,
    }


};

// modules for grouping.
const modules = {
    0: {
        title: "Dashboard",
        icon: "home",
        isExpendable: true,
    },
};

// component's access to roles.
const rolesConfig = {
    "dhan-admin": {
        routes: [...Object.values(components)],
    },
    "dhan-write": {
        routes: [...Object.values(components)],
    },
    // "sana-read": {
    //     routes: [
    //         components["sana-read"],
    //         components.ConvUI,
    //         components.dicaHelp,
    //         components.powerBI,
    //     ],
    // },
    // "sana-admin": {
    //     routes: [
    //         components["sana-admin"],
    //         components["sana-read"],
    //         components.ConvUI,
    //         components.dicaHelp,
    //         components.powerBI,
    //         components.debugTool,
    //     ],
    // },
};

export { modules, rolesConfig };
