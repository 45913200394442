const switcherItems = {
    analytics: {
        title: "Analytics",
        icon: "/media/svg/icons/Devices/Display1.svg",
        page: "/analytics",
    },
    PEP: {
        title: "PEP",
        icon: "/media/svg/icons/Devices/Display1.svg",
        page: "/pep",
    },
};

const switcherConfig = {
    "dhan-admin": {
        submenu: [...Object.values(switcherItems)],
    },
    "dhan-write": {
        submenu: [...Object.values(switcherItems)],
    }
};
export { switcherConfig };
