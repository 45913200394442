/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
// import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import MSALAuth from './modules/MSALAuth/MSALAuth';
import Homepage from './modules/Auth/pages/Homepage/Homepage'
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import SessionUtils from "./utils/session_utils";
import * as auth from "./modules/Auth/_redux/authRedux";
import PostAuth from './modules/MSALAuth/PostAuth';
import { useDispatch } from "react-redux";
import { WebChat } from "@pep/frontend-webchat-module";
import { PepRoutes, AnalyticsRoutes } from "./products"
export function Routes(props) {
  const history = useHistory();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const renderWithExpiry = () => {
    const isExpired = SessionUtils.sessionExpired();
    if (isExpired) {
      dispatch(auth.actions.logout());
      return;
    }
    return (
      <Layout>
        <PepRoutes />
        <AnalyticsRoutes />
      </Layout>
    );
  };

  return (
    <Switch>
      <Route path="/webchat-preview" exact component={WebChat} />
      {/* {
        !isAuthorized ? (
        
        <Route>
          <AuthPage />
        </Route>
      ) : (
          
          <Redirect from="/auth" to="/" />
        )
        } */}
        <Route exact path="/" component={Homepage} />
        <Route path="/auth/login" exact component={MSALAuth} />

        <Route path="/auth/success" exact component={PostAuth} />
        
        {!isAuthorized && (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path="/auth/login" exact component={MSALAuth} />
      )}
      {
        isAuthorized && <Redirect from="/auth" to="/" />
      }

      {/* <Route path="/auth/success" exact component={PostAuth} /> */}
      <Route path="/logout" component={Logout} />
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
          renderWithExpiry()
        )}
      {/* <Route path="/error" component={ErrorsPage} /> */}

    </Switch>
  );
}
