// import { PERMISSIONS_URLS } from "../pages/admin/constants";

export class Networkutils {
    handleError(response) {
        if (response.ok) {
            return response.json();
        }
        if (response.status >= 500) {
            let err = new Error("ServerError");
            err.status = response.status;
            throw err;
        }
        if (response.status <= 501) {
            let err = new Error("ApplicationError");
            err.status = response.status;
            err.res = response.json();
            throw err;
        }
    }

    fetchresponse(url, method, payload, headers = null) {
        const requestOptions = payload ? {
            method: method,
            body: JSON.stringify(payload),
            headers: headers,
        } : {
                method: method,
                headers: headers,
            }
        const request = new Request(url, requestOptions);
        return fetch(request).then(this.handleError);
    }

    // getPermissionURL(urlType, target, pk) {
    //     if (urlType !== "usergroup" && urlType in PERMISSIONS_URLS) {
    //         return PERMISSIONS_URLS[urlType];
    //     }
    //     if (urlType === "usergroup" && urlType in PERMISSIONS_URLS) {
    //         return PERMISSIONS_URLS[urlType].url;
    //     }
    //     if (target === "usergroup" && urlType in PERMISSIONS_URLS.usergroup) {
    //         return `${PERMISSIONS_URLS[target].url}/${pk}?${PERMISSIONS_URLS[target][urlType]}`;
    //     }
    // }

    getContentTypeheaders() {
        return {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }
    getAuthenticationHeaders(authToken) {
        return {
            ...this.getContentTypeheaders(),
            Authorization: `Bearer ${authToken}`,
        };
    }
}
