"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAllMessages = fetchAllMessages;
exports.fetchOneMessage = fetchOneMessage;
exports.createMessage = createMessage;
exports.updateMessage = updateMessage;
var _process$env = process.env,
    PEP_NODEJS_API_HOST = _process$env.PEP_NODEJS_API_HOST,
    PEP_LOCAL_API_HOST = _process$env.PEP_LOCAL_API_HOST;
PEP_NODEJS_API_HOST = PEP_NODEJS_API_HOST ? PEP_NODEJS_API_HOST : PEP_LOCAL_API_HOST; // to make it easier for devs to test APIs locally

function handleErrors(response) {
  if (!response.ok) {
    if (response.status >= 500) {
      var err = new Error('ServerError');
      err.status = response.status;
      throw err;
    }

    if (response.status <= 501) {
      var _err = new Error('ApplicationError');

      _err.status = response.status;
      throw _err;
    }
  } else {
    return response;
  }
}

function fetchAllMessages(authToken) {
  var orgId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1234;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/orgs/").concat(orgId, "/template?start=0&length=0"), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function fetchOneMessage(authToken, id, orgId) {
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/orgs/").concat(orgId, "/template/").concat(id), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    }
  }).then(handleErrors).then(function (res) {
    return res.json();
  })["catch"](function (err) {
    return err;
  });
}

function createMessage(authToken, payload) {
  var orgId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1234;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/orgs/").concat(orgId, "/template"), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}

function updateMessage(authToken, id, payload) {
  var orgId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1234;
  return fetch("".concat(PEP_NODEJS_API_HOST, "/api/orgs/").concat(orgId, "/template/").concat(id), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(authToken)
    },
    body: JSON.stringify(payload)
  }).then(handleErrors).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  })["catch"](function (err) {
    return err;
  });
}